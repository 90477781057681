<script>
  import {
    required,
    email,
    helpers
  } from "@vuelidate/validators";
  import appConfig from "../../../app.config";
  import { login, healthApi } from '../../helpers/api-apolo'

  export default {
    page: {
      title: "Login",
      meta: [{
        name: "description",
        content: appConfig.description,
      }, ],
    },
    data() {
      return {
        email: "",
        password: "",
        submitted: false,
        authError: null,
        authSuccess: null,
        tryingToLogIn: false,
        isAuthError: false,
        rememberMe: false,
        overlayLoading: false,
        passwordType: 'password'
      };
    },
    validations: {
      email: {
        required: helpers.withMessage("Email is required", required),
        email: helpers.withMessage("Please enter valid email", email),
      },
      password: {
        required: helpers.withMessage("Password is required", required),
      },
    },
    methods: {
      togglePasswordType() {
        if (this.passwordType === 'password') {
          this.passwordType = 'text'
        } else {
          this.passwordType = 'password'
        }
      },
      async signinapi() {
        try {
          this.overlayLoading = true
          if (this.rememberMe) {
            localStorage.setItem('rememberMe', true)
            localStorage.setItem('email', this.email)
            localStorage.setItem('password', this.password)
          } else {
            localStorage.removeItem('rememberMe')
            localStorage.removeItem('email')
            localStorage.removeItem('password')
          }
          await login(this.email, this.password)
          await this.$router.push({
            path: '/'
          });
          this.overlayLoading = false
        } catch (error) {
          this.authError = error.message;
          this.overlayLoading = false
        }
      }
    },
    async mounted() {
      healthApi();
      const remember = localStorage.getItem('rememberMe')
      if (remember) {
        this.rememberMe = true
        let email = localStorage.getItem('email')
        if (email) {
          this.email = email
        }
        let password = localStorage.getItem('password')
        if (password) {
          this.password = password
        }
      }
    }
  };
</script>

<template>
  <div class="loading-mobile" style="background-color: black; opacity:0.8" v-show="overlayLoading">
    <img class="overlay-apolo-mobile" style="max-height: 600px !important; max-width: 800px !important;" src="@/assets/images/logo_animated_dark.gif" alt="">
  </div>
<!--  <div class="loading" style="position: absolute;width: 100% !important;height: 100% !important;z-index: 999999999 !important;background-color: black;opacity: 0.8;" v-show="overlayLoading">-->
<!--    <img src="@/assets/images/logo_animated_dark.gif" alt="" style="position: absolute;width: 100% !important;width: 100% !important;opacity: 0.8 !important;top: 50%;left: 50%;transform: translate(-50%, -50%);">-->
<!--  </div>-->
  <div class="auth-page-wrapper pt-5">
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-overlay"></div>

      <div class="shape">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1440 120">
          <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8
            1440,40L1440 140L0 140z"></path>
        </svg>
      </div>
    </div>

    <!-- auth page content -->
    <div class="auth-page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mt-sm-5 mb-4 text-white-50">
              <div>
                <router-link to="/" class="d-inline-block auth-logo">
                  <img src="@/assets/images/logo-light.png" alt="" height="120" />
                </router-link>
              </div>
              <p class="mt-3 fs-15 fw-medium">
                O CRM pensado em <b>Você</b>
              </p>
            </div>
          </div>
        </div>
        <!-- end row -->

        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card mt-4">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">Seja Bem-vindo !</h5>
                  <p class="text-muted">Faça seu login para entrar no Apolo CRM.</p>
                </div>
                <div class="p-2 mt-4">
                  <b-alert v-model="authError" variant="danger" class="mt-3" dismissible>{{ authError }}</b-alert>
                  <b-alert v-model="authSuccess" variant="success" class="mt-3" dismissible>{{ authSuccess }}</b-alert>

                  <form @submit.prevent="tryToLogIn">
                    <div class="mb-3">
                      <label for="email" class="form-label">Email</label>
                      <input type="email" class="form-control" id="email" placeholder="Enter email" v-model="email" />
                      <div class="invalid-feedback">
                        <span></span>
                      </div>
                    </div>

                    <div class="mb-3">
                      <div class="float-end">
                        <router-link to="/forgot-password" class="text-muted">Esqueceu
                          a senha?</router-link>
                      </div>
                      <label class="form-label" for="password-input">Senha</label>
                      <div class="position-relative auth-pass-inputgroup mb-3">
                        <input :type="passwordType" v-model="password" class="form-control pe-5" placeholder="Enter password"
                          id="password-input" />
                        <button class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                          type="button" id="password-addon" @click.prevent="togglePasswordType">
                          <i class="ri-eye-fill align-middle"></i>
                        </button>
                        <div class="invalid-feedback">
                          <span></span>
                        </div>
                      </div>
                    </div>

                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" v-model="rememberMe" id="auth-remember-check" />
                      <label class="form-check-label" for="auth-remember-check"> Lembrar-se de mim</label>
                    </div>

                    <div class="mt-4">
                      <button v-if="authSuccess === null" class="btn btn-success w-100" type="submit" @click="signinapi">Entrar</button>
                      <button v-if="authSuccess !== null" class="btn btn-success w-100" type="submit"><i class="fs-3 mdi mdi-loading mdi-spin"></i></button>
                    </div>

<!--                    <div class="mt-4 text-center">-->
<!--                      <div class="signin-other-title">-->
<!--                        <h5 class="fs-13 mb-4 title">Sign In with</h5>-->
<!--                      </div>-->
<!--                      <div>-->
<!--                        <button type="button" class="btn btn-primary btn-icon-->
<!--                          waves-effect waves-light">-->
<!--                          <i class="ri-facebook-fill fs-16"></i>-->
<!--                        </button>-->
<!--                        <button type="button" class="btn btn-danger btn-icon-->
<!--                          waves-effect waves-light-->
<!--                          ms-1">-->
<!--                          <i class="ri-google-fill fs-16"></i>-->
<!--                        </button>-->
<!--                        <button type="button" class="btn btn-dark btn-icon-->
<!--                          waves-effect waves-light-->
<!--                          ms-1">-->
<!--                          <i class="ri-github-fill fs-16"></i>-->
<!--                        </button>-->
<!--                        <button type="button" class="btn btn-info btn-icon-->
<!--                          waves-effect waves-light-->
<!--                          ms-1">-->
<!--                          <i class="ri-twitter-fill fs-16"></i>-->
<!--                        </button>-->
<!--                      </div>-->
<!--                    </div>-->
                  </form>
                </div>
              </div>
              <!-- end card body -->
            </div>
            <!-- end card -->

<!--            <div class="mt-4 text-center">-->
<!--              <p class="mb-0">-->
<!--                Don't have an account ?-->
<!--                <router-link to="/register" class="fw-semibold text-primary-->
<!--                  text-decoration-underline">-->
<!--                  Signup-->
<!--                </router-link>-->
<!--              </p>-->
<!--            </div>-->
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0 text-muted">
                &copy; {{ new Date().getFullYear() }} Apolo CRM. Criado com
                <i class="mdi mdi-heart text-danger"></i> por Apolo Group
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->
  </div>
  <!-- end auth-page-wrapper -->
</template>
<style scoped>
  .loading-mobile {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    z-index: 999999999 !important;
    background-color: black;
    opacity: 0.8;
  }
  .loading-default {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    z-index: 999999999 !important;
  }
  .overlay-apolo-mobile {
    position: absolute;
    width: 100% !important;
    opacity: 0.8 !important;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
  }
  .overlay-apolo-default {
    width: 100% !important;
    opacity: 0.8 !important;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
  }
</style>